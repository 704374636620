<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="24"
              style="max-height: 65vh; height: 65vh; overflow-y: auto"
            >
              <ElRow :gutter="20">
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="user_uuid"
                    :label="$t('user')"
                    :class="`${
                      !form.parent_user_uuid ?
                        form.user_uuid 
                          ? 'autocomplete-redirection autocomplete-new-entity'
                          : 'autocomplete-new-entity'
                        : ''
                    }`"
                  >
                    <el-select-v2
                      :key="getUserKey()"
                      v-loading="getUserKey() === 0"
                      v-model="form.user_uuid"
                      :remote-method="searchUsers"
                      :placeholder="$t('select_an_user')"
                      remote
                      filterable
                      autocomplete
                      clearable
                      :disabled="!!form.parent_user_uuid"
                      clear-icon="Close"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchUsers()"
                      style="width: 100%"
                    />
                    <Icons
                      v-if="!form.parent_user_uuid"
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewUserDialog = true"
                    />
                    <Icons
                      v-if="form.user_uuid && !form.parent_user_uuid"
                      name="24px/arrow_top_right_purple"
                      class="new-entity-button"
                      @click="
                        newWindowItem('/configuration/users', {
                          uuid: form.user_uuid
                        })
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="parent_user_uuid"
                    :label="$t('parent_user')"
                    :class="`${
                      !form.user_uuid ?
                        form.parent_user_uuid 
                          ? 'autocomplete-redirection autocomplete-new-entity'
                          : 'autocomplete-new-entity'
                        : ''
                    }`"
                  >
                    <el-select-v2
                      :key="getParentUserKey()"
                      v-loading="getParentUserKey() === 0"
                      v-model="form.parent_user_uuid"
                      :remote-method="searchParentUsers"
                      :placeholder="$t('select_an_user')"
                      remote
                      filterable
                      autocomplete
                      clearable
                      :disabled="!!form.user_uuid"
                      clear-icon="Close"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchParentUsers()"
                      style="width: 100%"
                    />
                    <Icons
                      v-if="!form.user_uuid"
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewParentUserDialog = true"
                    />
                    <Icons
                      v-if="form.parent_user_uuid && !form.user_uuid"
                      name="24px/arrow_top_right_purple"
                      class="new-entity-button"
                      @click="
                        newWindowItem('/configuration/users', {
                          uuid: form.parent_user_uuid
                        })
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="first_name" :label="$t('first_name')">
                    <ElInput
                      v-model="form.first_name"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="last_name" :label="$t('last_name')">
                    <ElInput
                      v-model="form.last_name"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="date_of_birth"
                    :label="$t('date_of_birth')"
                  >
                    <ElDatePicker
                      v-model="form.date_of_birth"
                      class="!w-full"
                      type="date"
                      format="DD/MM/YYYY"
                      popper-class="custom-datepicker"
                      :editable="false"
                      :clearable="false"
                      @change="$emit('input')"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="gender" :label="$t('gender')">
                    <ElSelect
                      v-model="form.gender"
                      :autocomplete="true"
                      style="width: 100%"
                      :placeholder="$t('select_an_option')"
                      @change="$emit('input')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      clearable
                      clear-icon="Close"
                    >
                      <el-option
                        v-for="item in genders"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="email" :label="$t('email')">
                    <ElInput
                      v-model="form.email"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol
                  v-if="form.parent_user_uuid"
                  :span="width <= 700 ? 24 : 12"
                  :lg="12"
                >
                  <ElFormItem prop="relationship" :label="$t('relationship')">
                    <ElSelect
                      v-model="form.relationship"
                      :placeholder="$t('select_an_relationship')"
                      :autocomplete="true"
                      style="width: 100%"
                      class="prepend"
                      filterable
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      clearable
                      clear-icon="Close"
                    >
                      <ElOption
                        v-for="item in relationships"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <UtilsDocumentNumbers
                    ref="documentNumberComponent"
                    :documents="form.documents"
                    :is-required="false"
                    :background="true"
                    :column-sizes="{
                      span: width <= 700 ? 24 : 12,
                      lg: 12,
                    }"
                    @documentsToCreateOrUpdateOrDelete="
                      documentsToCreateOrUpdateOrDelete
                    "
                  />
                </ElCol>
              </ElRow>
            </ElCol>
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewParentUserDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewParentUserDialog = false), createParentUser($event)"
        @cancel="showNewParentUserDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_PASSENGER from '~/graphql/passengers/mutation/createPassenger.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  first_name: '',
  last_name: '',
  date_of_birth: '',
  gender: '',
  email: '',
  parent_user_uuid: '',
  user_uuid: '',
  phone: '',
  phone_country: '+1',
  relationship: '',
  documents: [],
  emergencyContacts: []
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const showNewUserDialog = ref(false)
const showNewParentUserDialog = ref(false)
const documents = ref([])
const emergencyContacts = ref([])
const relationships = ref(getRelationships())
const genders = ref(getGenders())
const documentNumberComponent = ref(null)

const rules = {
  first_name: { required },
  last_name: { required },
  email: { email },
}

onMounted(() => {
  initialUsers()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  first_name: [
    {
      required: true,
      message: t('first_name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  last_name: [
    {
      required: true,
      message: t('last_name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  email: [
    {
      type: 'email',
      message: t('email_is_invalid'),
      trigger: ['blur', 'change']
    }
  ],
  user_uuid: [
    {
      validator: (rule, value, callback) => {
        if (!value && !form.parent_user_uuid) {
          callback(new Error(t('user_or_parent_user_required')))
        } else {
          callback()
        }
      },
      trigger: ['change', 'blur']
    }
  ],
  parent_user_uuid: [
    {
      validator: (rule, value, callback) => {
        if (!value && !form.user_uuid) {
          callback(new Error(t('user_or_parent_user_required')))
        } else {
          callback()
        }
      },
      trigger: ['change', 'blur']
    }
  ],
  relationship: [
    {
      validator: (rule, value, callback) => {
        if (form.parent_user_uuid && !value) {
          callback(new Error(t('relationship_required_with_parent')))
        } else {
          callback()
        }
      },
      trigger: ['change', 'blur']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }

    if (!form.user_uuid && !form.parent_user_uuid) {
      $showError(t('user_or_parent_user_required'))
      return false
    }

    if (form.parent_user_uuid && !form.relationship) {
      $showError(t('relationship_required_with_parent'))
      return false
    }
  })

  const documentsValid = await documentNumberComponent.value?.validateAllDocuments()
  if (!documentsValid) {
    $showError(t('please_review_documents_section'))
    return
  }

  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_PASSENGER, {
    first_name: form.first_name,
    last_name: form.last_name,
    phone: form.phone,
    phone_country: form.phone_country,
    created_by: form.user_uuid,
    documents: documents.value,
    parent_user: form.parent_user_uuid,
    email: form.email,
    date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format('YYYY-MM-DD') : null,
    gender: form.gender,
    relationship: form.relationship,
    emergency_contacts: emergencyContacts.value
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createPassenger ?? null)
    loading.value = false
  }
}

const createUser = (event) => {
  if (event?.uuid) {
    form.user_uuid = event.uuid
    form.user = event
    searchUsers(event.first_name, form.user, null, true)
  }
}

const createParentUser = (event) => {
  if (event?.uuid) {
    form.parent_user_uuid = event.uuid
    form.parentUser = event
    searchUsers(event.first_name, form.parentUser, null, true)
  }
}

const documentsToCreateOrUpdateOrDelete = (newDocuments) => {
  documents.value = newDocuments
}

const emergencyContactsToCreateOrUpdateOrDelete = (newEmergencyContacts) => {
  emergencyContacts.value = newEmergencyContacts
}

const onUserChange = (uuid) => {
  if (!uuid) {
    form.user = null
    form.user_uuid = null
    return
  }
}

const onParentUserChange = (uuid) => {
  if (!uuid) {
    form.parentUser = null
    form.parent_user_uuid = null
    form.relationship = null
  }
}

// const onUserChange = async (uuid) => {
//   if (getSearchUsers()?.length && uuid) {
//     const user = getSearchUsers().find((item) => item.value === uuid)
//     const results = await usersAutocomplete({
//       search: user?.label?.split(' ')[0] ?? ''
//     })
//     if (results?.getAllUsers?.data?.find((item) => item.uuid === uuid)) {
//       const item = results.getAllUsers.data.find((item) => item.uuid === uuid)
//       form.first_name = item.first_name
//       form.last_name = item.last_name
//       form.date_of_birth = item.birthdate
//       form.phone = item.phone
//       form.phone_country = item.phone_country
//     }
//   }
// }
</script>

<style>
</style>